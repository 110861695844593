import React, { useState, useEffect, useMemo, useRef, LegacyRef } from "react";
import { Flex } from "@chakra-ui/react";

import Loading from "components/Loading";
import { useOrganization } from "hooks/_new/useOrganization";
import { useCurrentUser } from "contexts/currentUser";
import { formatDate, yearMonthDay } from "utils/dateFormats";
// import ReactMarkdown from 'react-markdown';
import getReactAppBackendURL from "utils/env";
import { useModal } from "contexts/modal";

import UlyssesChatSettingsModal from './UlyssesChatSettingsModal';
import Checkbox from "components/FormComponents/Checkbox";

const backendUrl = getReactAppBackendURL(); //process?.env?.REACT_APP_BACKEND_URL;

const Ulysses: React.FC = () => {

  const chatboxMessagesRef = React.useRef<HTMLDivElement>(null);

  const [user] = useCurrentUser();
  const org = useOrganization();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [azureChatHistoryList, setAzureChatHistoryList] = useState<any[]>([]);
  const [selectedAzureChat, setSelectedAzureChat] = useState<any>(null);

  const [usersMessage, setUsersMessage] = useState<string>("");
  const [applyFineTunning, setApplyFineTunning] = useState<boolean>(true); 

  const [showModal] = useModal();

  const handleClearErrorMessage = async (event: any) => {
    event.preventDefault();
    setErrorMessage("");
  }

  const loadChatHistoryList = async () => {
    setIsLoading(true);
    const xhr = new XMLHttpRequest();
    const endPoint = `/ai/chat/list/${user?.id}/${org.id}`;
    const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
    xhr.open('GET', link, true);
    xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    xhr.responseType = 'json';

    xhr.onload = () => {
      setIsLoading(false);
      if (xhr.status >= 200 && xhr.status < 300) {
        const resp = typeof xhr.response == "string" ? JSON.parse(xhr.response) : xhr.response;
        setAzureChatHistoryList(resp);
      } else {
        console.log("Error", xhr.statusText);
        setErrorMessage(xhr.statusText);
      }
    };

    xhr.onerror = () => {
      // this.setState({ error: 'Error fetching data.', loading: false });
      // console.log("Error", xhr.statusText);
      setErrorMessage(xhr.statusText);
    };

    xhr.send();
  }

  const loadSpecificChatHistory = async (chatId: any) => {
    setIsLoading(true);
    const xhr = new XMLHttpRequest();
    const endPoint = `/ai/chat/specific/${chatId}/${user?.id}/${org.id}`;
    const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
    xhr.open('GET', link, true);
    xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    xhr.responseType = 'json';

    xhr.onload = () => {
      setIsLoading(false);
      if (xhr.status >= 200 && xhr.status < 300) {
        const resp = typeof xhr.response == "string" ? JSON.parse(xhr.response) : xhr.response;
        if (resp.status === 500) {
          setErrorMessage(resp.error);
        } else {
          setSelectedAzureChat(resp.data);
          scrollToBottom();
        }
      } else {
        setErrorMessage(xhr.statusText);
        scrollToBottom();
      }
    };

    xhr.onerror = () => {
      setIsLoading(false);
      console.log("Error fetching chat data", xhr.statusText);
      setErrorMessage("Error fetching chat data");
      scrollToBottom();
    };

    xhr.send();
  }

  const handleSelectChatHistory = (event: any, item: any, index: any) => {
    event.preventDefault();
    setSelectedAzureChat(item);
    // load history chats
    setErrorMessage("");
    loadSpecificChatHistory(item.id);
  }

  const handleStartNewChat = async (event: any) => {
    event.preventDefault();
    //clear error message
    setErrorMessage("");
    // clear selected chat
    setSelectedAzureChat(null);
    // clear user input
    setUsersMessage("");
  }

  const handleUserInput = async (event: any) => {
    event.preventDefault();
    const input = event.target.value;
    setUsersMessage(input);
  }

  const handleSubmitForm = async (event: any) => {
    event.preventDefault();
    const promp_text = usersMessage.trim();
    if (promp_text.length == 0) {
      // can sent an empty prompt
      return;
    }

    // every message/prompt the user posts is the title of the chat as in, the last prompt is used as the title of the chat
    // we need to extract a maximum of 100 characters and append an ellipsis
    let title = promp_text;
    if (title.length > 100) {
      title = `${title.substring(0, 100)}...`;
    }

    setErrorMessage("");
    if (selectedAzureChat === null) {
      //begin a new chat, it will be overridden by the server response
      const newTempAzureChat = {
        id: 0,
        user_id: user?.id ?? 0,
        organization_id: org.id,
        title: title,
        chat: [{
          identity: 1,
          content: promp_text,
          role: "user",
          date: formatDate(new Date, yearMonthDay),
          date_time: 0
        }],
        remarks: {
          next_identity_no: 1,
          last_updated: formatDate(new Date, yearMonthDay),
        }
      };

      setSelectedAzureChat(newTempAzureChat);
      scrollToBottom();
      // push the new chat at the top of the history list
      const newList = [
        newTempAzureChat,
        ...azureChatHistoryList
      ]
      setAzureChatHistoryList(newList);
      sendPrompt(newTempAzureChat);

    } else {
      // extend - update existing chat
      const selectedAzureChatToUpdate = {
        ...selectedAzureChat
      }
      // it will be overridden by the server response
      const newChatItem = {
        identity: selectedAzureChatToUpdate.remarks.next_identity_no,
        content: promp_text,
        role: "user",
        date: formatDate(new Date, yearMonthDay),
        date_time: 0
      }
      selectedAzureChatToUpdate.chat.push(newChatItem);
      selectedAzureChatToUpdate.title = title;
      setSelectedAzureChat(selectedAzureChatToUpdate);
      setTimeout(() => {
        scrollToBottom();
        sendPrompt(selectedAzureChatToUpdate);
      }, 100);
    }
  }

  const handlePromptErrorResponse = (errorMessage: string, currentSelectedAzureChat: any) => {
    setErrorMessage(errorMessage);
    let selectedAzureChatToUpdate = {
      ...currentSelectedAzureChat
    }

    // remove previous prompt
    const cleanedChats = [];
    for (let index = 0; index < selectedAzureChatToUpdate.chat.length - 1; index++) {
      const chatMessage = selectedAzureChatToUpdate.chat[index];
      cleanedChats.push(chatMessage);
    }

    selectedAzureChatToUpdate.chat = cleanedChats;
    if (selectedAzureChatToUpdate.chat.length === 0) {
      selectedAzureChatToUpdate = null;
    }
    setSelectedAzureChat(selectedAzureChatToUpdate);

    // update the azureChatHistoryList
    const listTemp = [];
    for (let index = 0; index < azureChatHistoryList.length; index++) {
      const azureChatHistoryItem = azureChatHistoryList[index];
      if (azureChatHistoryItem.id.toString() === currentSelectedAzureChat.id.toString()) {
        if (selectedAzureChatToUpdate !== null) {
          listTemp.push({ ...selectedAzureChatToUpdate });
        }
      } else {
        listTemp.push(azureChatHistoryItem);
      }
    }
    setAzureChatHistoryList(listTemp);

  }

  const handlePromptDataResponse = (dataResponse: any, currentSelectedAzureChat: any) => {
    const selectedAzureChatToUpdate = {
      ...currentSelectedAzureChat
    }

    // remove previous prompt
    let cleanedChats = [];
    for (let index = 0; index < selectedAzureChatToUpdate.chat.length - 1; index++) {
      const chatMessage = selectedAzureChatToUpdate.chat[index];
      cleanedChats.push(chatMessage);
    }

    // insert the two new chats
    cleanedChats = [...cleanedChats, ...dataResponse.new_chats];

    // update the current selected chat
    selectedAzureChatToUpdate.chat = cleanedChats;
    selectedAzureChatToUpdate.id = dataResponse.azure_chat_history_id;
    selectedAzureChatToUpdate.title = dataResponse.title;
    selectedAzureChatToUpdate.remarks = dataResponse.remarks;

    setSelectedAzureChat(selectedAzureChatToUpdate);

    // update the azureChatHistoryList, it has to be the first on top
    let listTemp: any[] = [];
    for (let index = 0; index < azureChatHistoryList.length; index++) {
      const azureChatHistoryItem = azureChatHistoryList[index];
      if (azureChatHistoryItem.id.toString() !== selectedAzureChatToUpdate.id.toString()) {
        listTemp.push(azureChatHistoryItem);
      }
    }
    const updatedChat = { ...selectedAzureChatToUpdate, chat: [] };
    listTemp = [updatedChat, ...listTemp];
    // console.log("azureChatHistoryItem", listTemp);
    setAzureChatHistoryList(listTemp);

    //clear user input
    setUsersMessage("");
    scrollToBottom();
  }

  const sendPrompt = async (currentSelectedAzureChat: any) => {
    setIsLoading(true);
    const xhr = new XMLHttpRequest();
    const endPoint = `/ai/chat/prompt`;
    const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
    xhr.open('POST', link, true);
    xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    xhr.responseType = 'json';

    xhr.onload = () => {
      setIsLoading(false);
      if (xhr.status >= 200 && xhr.status < 300) {
        const resp = typeof xhr.response == "string" ? JSON.parse(xhr.response) : xhr.response;
        if (resp.status === 500) {
          handlePromptErrorResponse(resp.error, currentSelectedAzureChat);
        } else {
          handlePromptDataResponse(resp.data, currentSelectedAzureChat);
        }
        scrollToBottom();
      } else {
        handlePromptErrorResponse(xhr.statusText, currentSelectedAzureChat);
        scrollToBottom();
      }
    };

    xhr.onerror = () => {
      const errorMessage = `Error sending prompt: ${xhr.statusText}`;
      handlePromptErrorResponse(errorMessage, currentSelectedAzureChat);
      scrollToBottom();
    };

    const dataToSend = JSON.stringify({
      message: currentSelectedAzureChat.chat[currentSelectedAzureChat.chat.length - 1].content,
      organization_id: org.id,
      user_id: user?.id ?? 0,
      azure_chat_history_id: currentSelectedAzureChat.id,
      apply_fine_tunning: applyFineTunning
    });

    xhr.send(dataToSend);
  }

  const scrollToBottom = () => {
    if (chatboxMessagesRef && chatboxMessagesRef.current) {
      chatboxMessagesRef.current.scrollTop = chatboxMessagesRef.current.scrollHeight + 50; //({ behavior: "smooth" });
    }
  }

  const settingsModal = useMemo(() => {
    const currentModal = {
      title: "Ulysses Chat Settings",
      component: UlyssesChatSettingsModal,
      componentProps: {
        // buttonLabel: t("actions.update_information"),
        // id,
        // onClickDelete: () => {
        //   if (type === TableTypeEnum.Report) {
        //     showModal(deleteReportModal);
        //   }else{
        //     showModal(deleteModal);
        //   }
        // },
        // program: program
      },
      size: "6xl"
    };

    return currentModal;
  }, [showModal]);

  const handleOpenChatSettings = () => {
    showModal(settingsModal);
  }


  useEffect(() => {
    //clear error message
    setErrorMessage("");
    // clear all the chat history
    setAzureChatHistoryList([]);
    // clear selected chat
    setSelectedAzureChat(null);
    // Load this users organisation chat history
    const historyLoader = async () => {
      loadChatHistoryList();
      scrollToBottom();
    }
    historyLoader();

    // const postData = async () => {
    //   sendPrompt(":greetings", []);
    // };
    // postData();
  }, [org]);



  return (
    <Flex flexDirection="row" alignItems="center" justify="center" padding="0rem" id="ulysses-chat-window"  >
      {/* <Flex alignSelf="stretch" flexDirection="column" alignItems="start" justify="start" width="400px" >

      </Flex> */}
      <div className="chat-history-container" >
        <Flex padding="0.5rem" flexDirection="row" justifyContent="space-between" alignItems="center" fontWeight="bold" fontSize="16px" borderBottom={"1px solid #ddd8d8"} backgroundColor="#f2f1f1" alignSelf="stretch" >
          <span>CHAT HISTORY</span>

          <button className="new-chat-btn" disabled={isLoading} type="button" onClick={handleStartNewChat} >New Chat</button>
        </Flex>
        <Flex flex={1} flexDirection="row" alignItems="start" className="chat-historical-prompts" justify="center" padding="10px"  >
          {azureChatHistoryList.map((item: any, index: number) => {
            return (
              <div key={index}
                onClick={(e) => isLoading ? false : handleSelectChatHistory(e, item, index)}
                className={`history-item ${selectedAzureChat !== null && item.id.toString() === selectedAzureChat.id.toString() ? 'active' : ''} ${isLoading ? 'disabled' : ''}`}>
                <div className="date">
                  <span>{item.remarks.last_updated}</span>
                  <span className="chat-history-id">#{item.id}</span>
                </div>
                <div className="title">{item.title}</div>
              </div>
            );
          })}
        </Flex>
      </div>
      <div className="chat-container" >
        <div className="chat-box">
          {errorMessage.length > 0 &&
            <div className="chat-error">
              <div className="error-message">{errorMessage}</div>
              <div className="close-error-btn" onClick={handleClearErrorMessage}>X</div>
            </div>
          }
          <Flex padding="0.5rem" flexDirection="row" justifyContent="space-between" alignItems="center" fontWeight="bold" fontSize="16px" borderBottom={"1px solid #ddd8d8"} backgroundColor="#f2f1f1" alignSelf="stretch" >
            <div>
              <div style={{ width: "fit-content" }}>
                {/* note, this was isChecked={viewAdminVersions} */}
                <Checkbox
                  name="apply-fine-tunning"
                  isChecked={applyFineTunning}
                  onChange={(e) => setApplyFineTunning(e.target.checked)}
                >
                  Apply Fine Tunning
                </Checkbox>
              </div>
            </div>

            {user?.role === "ADMIN" &&
              <button
                className="chat-settings-btn"
                disabled={isLoading}
                type="button" onClick={handleOpenChatSettings} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ width: "20px", height: "20px", marginRight: "5px" }}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg> Fine Tunning Config
              </button>
            }
          </Flex>
          <div className="messages" id="messages" ref={chatboxMessagesRef}>
            {selectedAzureChat === null &&
              <div className="new-chat-form" >
                <h2>Ulysses <small style={{ color: "#868282" }}>(*beta)</small></h2>
                <h4>Start a new AI Chat with your documents</h4>
              </div>
            }
            {selectedAzureChat !== null &&
              <div className="messages-holder">
                {/* Messages will be shown here */}
                {selectedAzureChat.chat.map((item: any, index: number) => {
                  return (
                    <div key={index} className={`message ${item.role}`}>
                      <div dangerouslySetInnerHTML={{ __html: item.content }} ></div>
                      {(item.files && Object.keys(item.files).length > 0) &&
                        <div className="message-files-list">
                          {Object.keys(item.files).map((fileKey, i) => (
                            <div className="message-files-list-item" key={i}>
                              <span className="message-files-list-item-doc-key">{fileKey}&nbsp;&nbsp;&nbsp;refers&nbsp;to&nbsp;:</span>
                              <a className="message-files-list-item-anchor" rel="noreferrer" target="_blank" href={item.files[fileKey].url} >{item.files[fileKey].file_name}</a>
                            </div>
                          ))}
                        </div>
                      }
                    </div>);
                })}
              </div>
            }
          </div>
          <form onSubmit={handleSubmitForm} className="input-box" id="chatForm">
            <div className="loader">
              {isLoading && <Loading />}
            </div>

            <input disabled={isLoading} type="text" id="userInput" placeholder="Type a message..." onChange={handleUserInput} value={usersMessage} />
            <button disabled={isLoading} type="submit">Send</button>
          </form>
        </div>
      </div>
    </Flex >
  );
};


export default Ulysses;
